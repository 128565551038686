export function isTimestampValid(timestamp: string | null) {
  // if no timestamp bypassValidation
  if (!timestamp) return true;
  // Convert the timestamp to milliseconds
  const timestampMs = parseInt(timestamp, 10);
  if (isNaN(timestampMs)) {
    return false;
  }
  // Get the current date in milliseconds
  const currentDateMs = Date.now();
  // Check if the timestamp is in the future
  return timestampMs >= currentDateMs;
}
