import {
  loginCustomer,
  registerCustomer,
  resetOTP as resetOtpApi,
  resetPassword as resetPasswordAPI,
  updateCustomer,
} from "../api/customers";
import { Login } from "../components/4-pages/Login";
import { User } from "../types";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const MODIFIED_TEMP_PASSWORD = `votre mot de passe temporaire a déjà été modifié,
    si vous avez perdu / oublié votre mot de passe, nous vous invitons à vous rendre
    sur "Mot de passe oublié" sur la page d'authentification`;
const STILL_CREATED_ACCOUNT = "votre compte a déjà été créé";
const EMAIL_SENT = "Un email vient de vous être envoyé";
const UNTRACEABLE_ADRESS = "Votre adresse email est introuvable";
const INCORRECT_IDENTIFIERS = "Vos identifiants sont incorrects";
const SUCCESS_ACCOUNT_CREATION = "Votre compte a bien été créé";
const SUCCESS_PASSWORD_UPDATE = "Votre mot de passe a bien été modifié";

const LoginContainer = ({
  isUpdate = false,
  isAgent = false,
  login,
  setStayLoggedIn,
}: {
  isUpdate?: boolean;
  isAgent?: boolean;
  login: (user: User) => void;
  setStayLoggedIn: (stay: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  function agentLogin() {}
  async function customerLogin(loginInfo: {
    email: string;
    password: string;
    stayConnected?: boolean;
    captchaValue: string;
    token?: string;
  }) {
    try {
      const loginData = await loginCustomer({
        login: loginInfo.email,
        password: loginInfo.password,
        captchaValue: loginInfo.captchaValue,
        token: loginInfo.token,
      });
      if (loginData.token) {
        const authUser: User = loginData.user;
        authUser["accessToken"] = loginData.token;
        authUser["id"] = authUser["customerId"]!;
        login(authUser);
        setStayLoggedIn(
          loginInfo.stayConnected !== undefined ? loginInfo.stayConnected : true
        );
        navigate("/home", { replace: true });
      }
    } catch (error) {
      enqueueSnackbar(INCORRECT_IDENTIFIERS, {
        variant: "error",
      });
    }
  }

  const propsLogin = isAgent ? agentLogin : customerLogin;

  async function register(loginInfo: {
    email: string;
    password: string;
    token: string;
    captchaValue: string;
  }) {
    try {
      const registerData = await registerCustomer({
        login: loginInfo.email,
        password: loginInfo.password,
        token: loginInfo.token,
        captchaValue: loginInfo.captchaValue,
      });
      if (registerData.token) {
        const authUser = registerData.user;
        authUser["accessToken"] = registerData.token;
        authUser["id"] = authUser["customerId"]!;
        login(authUser);
        navigate("/home", { replace: true });
        enqueueSnackbar(SUCCESS_ACCOUNT_CREATION, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(`${INCORRECT_IDENTIFIERS} ou ${STILL_CREATED_ACCOUNT}`, {
        variant: "error",
      });
    }
  }

  async function update(loginInfo: {
    email: string;
    password: string;
    token: string;
    captchaValue: string;
  }) {
    try {
      const updateData = await updateCustomer({
        login: loginInfo.email,
        password: loginInfo.password,
        token: loginInfo.token,
        captchaValue: loginInfo.captchaValue,
      });
      if (updateData.token) {
        const authUser = updateData.user;
        authUser["accessToken"] = updateData.token;
        authUser["id"] = authUser["customerId"]!;
        login(authUser);
        navigate("/home", { replace: true });
        enqueueSnackbar(SUCCESS_PASSWORD_UPDATE, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(INCORRECT_IDENTIFIERS, {
        variant: "error",
      });
    }
  }

  const resetPassword = async (infos: {
    email: string;
    captchaValue: string;
  }) => {
    try {
      await resetPasswordAPI(infos);
      enqueueSnackbar(EMAIL_SENT, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(`${UNTRACEABLE_ADRESS}`, {
        variant: "error",
      });
    }
  };

  const resetOTP = async (infos: {
    email?: string;
    phoneNumber?: string;
    captchaValue: string;
  }) => {
    try {
      await resetOtpApi(infos);
      enqueueSnackbar(EMAIL_SENT, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(`${UNTRACEABLE_ADRESS} ou ${MODIFIED_TEMP_PASSWORD}`, {
        variant: "error",
      });
    }
  };

  return (
    <Login
      token={token}
      isAgent={isAgent}
      isUpdate={isUpdate}
      login={propsLogin}
      register={register}
      updatePassword={update}
      resetPassword={resetPassword}
      resetOTP={resetOTP}
    />
  );
};

export default LoginContainer;
