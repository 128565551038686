import React, {useEffect, useState} from "react";
import { Contract } from "../../types";
import Button from "../1-atoms/Button";
import "../../css/contrats.css";

type Props = {
    contractData: Contract;
    isPlaying: boolean;
    setPlaying: () => void;
}

const Contrat = (props: Props) => {
    let player: HTMLAudioElement | undefined = undefined;

    const { contractData, setPlaying, isPlaying } = props;
    const [error, setError] = useState(false);
    useEffect(() => {
        if (player !== undefined) {
            if(isPlaying) {
                player.play()
            } else {
                player.pause();
                player.currentTime = 0
            }   
        }
    }, [isPlaying, player]);
    const convertDate = (inputFormat: string) => {
        const pad = (s: number) => { return (s < 10) ? '0' + s : s; }
        const d = new Date(inputFormat);
        const datePart = [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
        const timePart = [pad(d.getHours()), pad(d.getMinutes())].join(':');
        
        return `${datePart} à ${timePart}`;
    }

    return (
        <>
            <div className="content">
                <div className="infos">
                    <div>
                        <p>{ `Mon accord du ${convertDate(contractData.date)}` }</p>
                    </div>
                    {
                        !error &&
                        <>
                            <Button label="Ecouter mon accord" onClick={setPlaying}/>
                            <audio
                                ref={(element) => {if (element !== null) {player = element}}}
                                id={`audioContract-` + contractData.id}
                                preload="auto"
                                controls
                                onError={() => setError(true)}
                                controlsList="nodownload noplaybackrate"
                                src={contractData.data}>
                            </audio>
                        </>
                    }
                    {
                        error && 
                        <p>Enregistrement de votre contrat actuellement indisponible.</p>
                    }
                    
                </div>
            </div>
        </>
    )
}

export default Contrat;