import React, { useState } from "react";
import LabelledTextField from "../1-atoms/LabelledTextField";
import Checkbox from "../1-atoms/Checkbox";
import "../../css/login.css";
import locker from "../../assets/images/SFR SECURITE PRO_RGB.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../1-atoms/Button";
import LoginModal from "../2-molecules/LoginModal";
import { isTimestampValid } from "../../util/date";

const EMAIL_LABEL = "Numéro de ligne mobile, email ou NeufID";
const AGENT_EMAIL_LABEL = "Veuillez saisir votre login et mot de passe";
const PASSWORD_LABEL = "Saisissez votre mot de passe provisoire";
const CONNECT_LABEL = "Rester connecté";

const captchaKey = process.env["REACT_APP_RECAPTCHA_KEY"] ?? "  ";
type Props = {
  token?: string;
  isAgent: boolean;
  isUpdate?: boolean;
  register: (registerInfo: {
    email: string;
    password: string;
    token: string;
    captchaValue: string;
  }) => void;
  updatePassword: (updateInfo: {
    email: string;
    password: string;
    token: string;
    captchaValue: string;
  }) => void;
  login: (loginInfo: {
    email: string;
    password: string;
    stayConnected?: boolean;
    captchaValue: string;
  }) => void;
  resetOTP: (infos: {
    email?: string;
    phoneNumber?: string;
    captchaValue: string;
  }) => void;
  resetPassword: (infos: { email: string; captchaValue: string }) => void;
};

interface IErrorFieldState {
  isErrorEmail: boolean;
  isErrorPassword: boolean;
  isCaptchaError: boolean;
}

const errorState: IErrorFieldState = {
  isErrorEmail: false,
  isErrorPassword: false,
  isCaptchaError: false,
};

export type TModalView =
  | "login"
  | "first-connexion"
  | "forgotten-password"
  | "change-password"
  | "generate-password"
  | "expired-forgotten-password-link"
  | "expired-generate-password-link";

export const Login = (props: Props) => {
  const {
    isAgent,
    login,
    token,
    register,
    resetOTP,
    resetPassword,
    updatePassword,
    isUpdate,
  } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<IErrorFieldState>(errorState);

  const [stayConnected, setStayConnected] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const validationPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*(\d|[@$!%*#?&]))[a-zA-Z\d@$!%*#?&]{8,}$/;
  const validationEmail = /\w+(\w|\.)*@\w+\.\w{2,3}/;
  const params = new URLSearchParams(window.location.search);
  const expirationDateparam = params.get("date");

  function getModalView(): TModalView {
    if (token) {
      return isTimestampValid(expirationDateparam)
      ? isUpdate? "change-password":"first-connexion"
      : isUpdate? "expired-forgotten-password-link":"expired-generate-password-link";
    }
    if (isUpdate) return "change-password";
    return "login";
  }

  const [modalView, setModalView] = useState<TModalView>(getModalView());

  const onChangeHandler = (value: string, fieldName: "email" | "password") => {
    if (value !== "") {
      setError(errorState);
    }

    fieldName === "email" && setEmail(value);
    fieldName === "password" && setPassword(value);
  };

  const updateCaptchaValue = (value: string | null) => {
    if (value !== null) {
      setCaptchaValue(value);
    } else {
      setCaptchaValue("");
    }
  };

  const onLoginClick = async () => {
    const isEmailValid = validationEmail.test(email.toLowerCase());
    const isPasswordValid = validationPassword.test(password);
    const isCaptchaValid = captchaValue !== "";
    setError({
      isErrorEmail: !isEmailValid,
      isErrorPassword: !isPasswordValid,
      isCaptchaError: !isCaptchaValid,
    });

    if (
      modalView === "login" &&
      isEmailValid &&
      isPasswordValid &&
      captchaValue !== ""
    ) {
      login({ email, password, stayConnected, captchaValue });
    }
  };

  return (
    <>
      <LoginModal
        tokenProps={token}
        setModalView={setModalView}
        modalView={modalView}
        login={login}
        register={register}
        updatePassword={updatePassword}
        resetOTP={resetOTP}
        resetPassword={resetPassword}
      />
      <div className="phishing-infos">
        <div className="tooltip">
          Info Phishing
          <img src={locker} id="cadenas" alt="cadenas" />
          <div className="tooltiptext">
            <a
              target="_blank"
              href="https://assistance.sfr.fr/sfrmail-appli/phishing-spam/proteger-phishing.html"
              rel="noreferrer noopener"
            >
              <span>&#10146;</span> Comment se protéger du phishing et signaler
              un e-mail suspect à SFR ?
            </a>
            <a
              target="_blank"
              href="https://assistance.sfr.fr/sfrmail-appli/phishing-spam/email-phishing-sms-frauduleux.html"
              rel="noreferrer noopener"
            >
              <span>&#10146;</span> Comment reconnaître et signaler un e-mail de
              phishing, un SMS ou appel frauduleux ?
            </a>
          </div>
        </div>
      </div>
      <div className="login">
        <form>
          <h2>
            {" "}
            {isAgent ? "Espace Accord - Login Agent" : "Mon espace Accord"}
          </h2>
          <LabelledTextField
            isError={error.isErrorEmail}
            label={isAgent ? AGENT_EMAIL_LABEL : EMAIL_LABEL}
            id="email"
            name="email"
            placeholder="&#xF007;    Identifiant"
            className="icon-input"
            value={email}
            onChange={(value) => onChangeHandler(value, "email")}
          />
          {error.isErrorEmail ? (
            <p className="infoModalError">
              Merci de renseigner une adresse mail valide
            </p>
          ) : null}

          <LabelledTextField
            isError={error.isErrorPassword}
            label={PASSWORD_LABEL}
            id="password"
            placeholder="&#xF084;   Mot de passe"
            isPassword
            value={password}
            onChange={(value) => onChangeHandler(value, "password")}
          />
          {error.isErrorPassword ? (
            <p className="infoModalError">{`Merci de renseigner un mot de passe comprenant minimum 8 caractères,
                  1 minuscule, 1 majuscule, 1 chiffre ou caractère spécial : @$!%*#?&`}</p>
          ) : null}
          {!isAgent && (
            <>
              <div className="connect">
                <Checkbox
                  id="connect"
                  isChecked={stayConnected}
                  onChange={() => setStayConnected(!stayConnected)}
                  label={CONNECT_LABEL}
                />
              </div>
            </>
          )}
          <div className="captcha">
            <ReCAPTCHA sitekey={captchaKey} onChange={updateCaptchaValue} />

            {error.isCaptchaError && (
              <p className="infoModalError">Invalid captcha</p>
            )}
          </div>
        </form>
        <Button
          className="linkto ppal_link"
          onClick={onLoginClick}
          label="Me connecter"
        />
        {!isAgent && (
          <div className="help">
            <h3>{`Besoin d'aide`}</h3>
            <div className="links">
              <div>
                <button
                  className="link-password"
                  id="forgotten_password"
                  onClick={() => {
                    setModalView("forgotten-password");
                  }}
                >
                  <span>&#xF105;</span> Mot de passe oublié
                </button>
              </div>
              <div>
                <button
                  className="link-first"
                  id="first_connection"
                  onClick={() => {
                    setModalView("first-connexion");
                  }}
                >
                  <span>&#xF105;</span> Première connexion
                </button>
              </div>
              <div>
                <p className="tooltip">
                  <span>&#xF105;</span> Identifiant oublié*
                  <div className="tooltiptext">
                    <p>
                      Votre identifiant est votre adresse email de contact que
                      vous avez communiqué lors de votre commande
                    </p>
                  </div>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
