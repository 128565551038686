import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../1-atoms/Button";
import LabelledTextField from "../1-atoms/LabelledTextField";
import { Modal } from "../1-atoms/Modal";
import { TModalView } from "../4-pages/Login";

const PASSWORD_LABEL = "Saisissez votre mot de passe provisoire";
const NEW_PASSWORD_LABEL = "Saisissez votre nouveau mot de passe";
const CONFIRM_PASSWORD_LABEL = "Confirmez votre mot de passe";

const captchaKey = process.env["REACT_APP_RECAPTCHA_KEY"] ?? "  ";

type Props = {
  modalView: TModalView;
  setModalView: (view: TModalView) => void;
  tokenProps?: string;
  register: (registerInfo: {
    email: string;
    password: string;
    token: string;
    captchaValue: string;
  }) => void;
  updatePassword: (updateInfo: {
    email: string;
    password: string;
    token: string;
    captchaValue: string;
  }) => void;
  login: (registerInfo: {
    email: string;
    password: string;
    token?: string;
    captchaValue: string;
    stayConnected?: boolean;
  }) => void;
  resetOTP: (infos: {
    email?: string;
    phoneNumber?: string;
    captchaValue: string;
  }) => void;
  resetPassword: (infos: { email: string; captchaValue: string }) => void;
};

interface IErrorFieldState {
  isErrorEmail: boolean;
  isErrorPassword: boolean;
  isErrorpasswordVerification: boolean;
  isCaptchaError: boolean;
}

const errorState: IErrorFieldState = {
  isErrorEmail: false,
  isErrorPassword: false,
  isErrorpasswordVerification: false,
  isCaptchaError: false,
};

const LoginModal = (props: Props) => {
  const {
    register,
    tokenProps = "",
    setModalView,
    modalView,
    resetOTP,
    resetPassword,
    updatePassword,
  } = props;

  const [token, setToken] = useState(tokenProps);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(""); // TODO: Secure
  const [passwordVerification, setPasswordVerification] = useState(""); // TODO: Secure
  const [captchaValue, setCaptchaValue] = useState("");

  const [error, setError] = useState<IErrorFieldState>(errorState);

  const validationPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*(\d|[@$!%*#?&]))[a-zA-Z\d@$!%*#?&]{8,}$/;
  const validationEmail = /\w+(\w|\.)*@\w+\.\w{2,3}/;

  const onChange = (
    value: string,
    fieldName: "email" | "password" | "passwordVerification"
  ) => {
    if (value !== "") {
      setError(errorState);
    }

    fieldName === "email" && setEmail(value);
    fieldName === "password" && setPassword(value);
    fieldName === "passwordVerification" && setPasswordVerification(value);
  };

  const onLoginClick = async () => {
    let isEmailValid = false;
    let isPasswordValid = false;
    const isCaptchaValid = captchaValue !== "";

    switch (modalView) {
      case "forgotten-password":
      case "expired-forgotten-password-link":
        isEmailValid = validationEmail.test(email.toLowerCase());

        setError((prev) => ({
          ...prev,
          isErrorEmail: !isEmailValid,
          isCaptchaError: !isCaptchaValid,
        }));
        if (isEmailValid && isCaptchaValid) {
            resetPassword({ email, captchaValue });
          actionCloseModal();
        }
        break;
      case "generate-password":
        case "expired-generate-password-link":
        isEmailValid = validationEmail.test(email.toLowerCase());
        setError((prev) => ({
          ...prev,
          isErrorEmail: !isEmailValid,
          isCaptchaError: !isCaptchaValid,
        }));
        if (isEmailValid && isCaptchaValid) {
          resetOTP({ email, captchaValue });
          actionCloseModal();
        }
        break;
      case "first-connexion":
        isEmailValid = validationEmail.test(email.toLowerCase());
        isPasswordValid = validationPassword.test(password);

        setError((prev) => ({
          ...prev,
          isErrorEmail: !isEmailValid,
          isErrorPassword: !isPasswordValid,
          isErrorpasswordVerification: passwordVerification !== password,
          isCaptchaError: !isCaptchaValid,
        }));
        if (
          isEmailValid &&
          token &&
          isPasswordValid &&
          isCaptchaValid &&
          passwordVerification === password
        ) {
          register({ email, password, token, captchaValue });
          actionCloseModal();
        }
        break;

      case "change-password":
        isEmailValid = validationEmail.test(email.toLowerCase());
        isPasswordValid = validationPassword.test(password);

        setError((prev) => ({
          ...prev,
          isErrorEmail: !isEmailValid,
          isErrorPassword: !isPasswordValid,
          isErrorpasswordVerification: passwordVerification !== password,
          isCaptchaError: !isCaptchaValid,
        }));
        if (
          isEmailValid &&
          isPasswordValid &&
          passwordVerification === password
        ) {
          updatePassword({ email, password, token, captchaValue });
          actionCloseModal();
        }
        break;

      default:
        break;
    }
  };

  const actionCloseModal = () => {
    setModalView("login");
    setCaptchaValue("");

    // reset state
    setError({
      isErrorEmail: false,
      isErrorPassword: false,
      isErrorpasswordVerification: false,
      isCaptchaError: false,
    });

    setEmail("");
    setPassword("");
    setPasswordVerification("");
  };

  const updateCaptchaValue = (value: string | null) => {
    if (value !== null) {
      setCaptchaValue(value);
    } else {
      setCaptchaValue("");
    }
  };

  const isTokenValid = token !== "";
  const buttonText =
    modalView === "forgotten-password" || modalView === "generate-password"
      ? "Valider"
      : "M'enregistrer";

  let modalTitle = "";
  let modalClass = "";

  switch (modalView) {
    case "forgotten-password":
      modalTitle = "Mot de passe oublié";
      modalClass = "littleModal";
      break;
    case "first-connexion":
      modalTitle = "Première connexion";
      modalClass = "bigModal";
      break;
    case "expired-generate-password-link":
      modalTitle = "Votre lien a expiré, regénérez un nouveau mot de passe temporaire";
      modalClass = "littleModal";
      break;
    case "expired-forgotten-password-link":
      modalTitle = "Votre lien a expiré, réinitialisez votre mot de passe";
      modalClass = "littleModal";
      break;
    case "generate-password":
      modalTitle = "Regénérer un nouveau mot de passe temporaire";
      modalClass = "littleModal";
      break;
    case "change-password":
      modalTitle = "Changement de mot de passe";
      modalClass = "mediumModal";
      break;
  }

  modalClass += " modal-wrapper";

  return (
    <Modal
      isShowing={modalView !== "login"}
      modalClass={modalClass}
      hide={actionCloseModal}
      title={modalTitle}
    >
      <>
        <form className="changeInfos">
          {/* Mot de passe oublié */}
          {modalView === "forgotten-password" || modalView === "expired-forgotten-password-link" ? (
            <>
              <p className="long_intro">
                {`Si vous avez perdu / oublié votre mot de passe, vous pouvez le réinitialiser en indiquant l'email
                que vous avez indiqué lors de votre commande.`}
                <br />
                Suivez ensuite les différentes étapes pour définir un nouveau
                mot de passe et accéder à vos enregistrements.
              </p>
              <LabelledTextField
                isError={error.isErrorEmail}
                label="Identifiant"
                id="emailModal2"
                name="email"
                value={email}
                onChange={(value) => onChange(value, "email")}
              />

              {error.isErrorEmail ? (
                <p className="infoModalError">
                  Merci de renseigner une adresse mail valide
                </p>
              ) : null}
            </>
          ) : null}

          {/* Première connexion */}
          {modalView === "first-connexion" ? (
            <>
              <LabelledTextField
                isError={error.isErrorEmail}
                label="Adresse email"
                placeholder="Identifiant"
                id="email"
                name="email"
                value={email}
                onChange={(value) => onChange(value, "email")}
              />
              {error.isErrorEmail ? (
                <p className="infoModalError">
                  Merci de renseigner une adresse mail valide
                </p>
              ) : null}

              <LabelledTextField
                isError={!isTokenValid && token !== ""}
                label={PASSWORD_LABEL}
                placeholder="Mot de passe provisoire"
                id="token"
                name="token"
                value={token}
                onChange={setToken}
              />
              {!isTokenValid && token !== "" ? (
                <p className="infoModalError">
                  Merci de renseigner votre mot de passe provisoire
                </p>
              ) : null}

              <button
                className="link_expired_mdp"
                onClick={() => {
                  setModalView("generate-password");
                }}
              >
                Mot de passe temporaire expiré ?
              </button>

              <LabelledTextField
                isError={error.isErrorPassword}
                label={NEW_PASSWORD_LABEL}
                id="passwordModal"
                placeholder="Mot de passe"
                isPassword
                value={password}
                onChange={(value) => onChange(value, "password")}
              />

              {error.isErrorPassword ? (
                <p className="infoModalError">{`Merci de renseigner un mot de passe comprenant minimum 8 caractères, 1 minuscule, 1 majuscule, 1 chiffre ou caractère spécial : @$!%*#?&`}</p>
              ) : null}

              <LabelledTextField
                isError={error.isErrorpasswordVerification}
                label={CONFIRM_PASSWORD_LABEL}
                id="password-verification"
                placeholder="Confirmation de mot de passe"
                isPassword
                value={passwordVerification}
                onChange={(value) => onChange(value, "passwordVerification")}
              />
              {error.isErrorpasswordVerification ? (
                <p className="infoModalError">
                  Il y a une erreur dans la confirmation du mot de passe
                </p>
              ) : null}
            </>
          ) : null}

          {/* Regénérer un nouveau mot de passe temporaire */}
          {modalView === "generate-password" || modalView === "expired-generate-password-link" ? (
            <>
              <p className="long_intro">
                Votre identifiant est votre email de contact que vous avez
                communiqué lors de votre commande.
                <br />
                Le mot de passe temporaire est valable 30 minutes.
              </p>

              <LabelledTextField
                isError={error.isErrorEmail}
                label="Identifiant"
                id="emailModal"
                name="email"
                value={email}
                onChange={(value) => onChange(value, "email")}
              />

              {error.isErrorEmail && email !== "" ? (
                <p className="infoModalError">
                  Merci de renseigner une adresse mail valide
                </p>
              ) : null}
            </>
          ) : null}
          {modalView === "change-password" ? (
            <>
              <LabelledTextField
                isError={error.isErrorEmail}
                label="Adresse email"
                placeholder="Identifiant"
                id="email"
                name="email"
                value={email}
                onChange={(value) => onChange(value, "email")}
              />

              {error.isErrorEmail ? (
                <p className="infoModalError">
                  Merci de renseigner une adresse mail valide
                </p>
              ) : null}

              <LabelledTextField
                isError={error.isErrorPassword}
                label={NEW_PASSWORD_LABEL}
                id="passwordModal"
                placeholder="Mot de passe"
                isPassword
                value={password}
                onChange={(value) => onChange(value, "password")}
              />

              {error.isErrorPassword ? (
                <p className="infoModalError">{`Merci de renseigner un nouveau mot de passe comprenant minimum 8 caractères,
                  1 minuscule, 1 majuscule, 1 chiffre ou caractère spécial : @$!%*#?&`}</p>
              ) : null}

              <LabelledTextField
                isError={error.isErrorpasswordVerification}
                label={CONFIRM_PASSWORD_LABEL}
                id="password-verification"
                placeholder="Confirmation de mot de passe"
                isPassword
                value={passwordVerification}
                onChange={(value) => onChange(value, "passwordVerification")}
              />

              {error.isErrorpasswordVerification ? (
                <p className="infoModalError">
                  Il y a une erreur dans la confirmation du mot de passe
                </p>
              ) : null}

              <button
                className="link_expired_mdp"
                onClick={() => {
                  setModalView("forgotten-password");
                }}
              >
                Lien expiré ?
              </button>
            </>
          ) : null}

          {/* captcha */}
          <div className="captcha">
            <ReCAPTCHA sitekey={captchaKey} onChange={updateCaptchaValue} />

            {error.isCaptchaError && (
              <p className="infoModalError">Invalid captcha</p>
            )}
          </div>
        </form>

        <Button
          className="button-form linkto"
          onClick={onLoginClick}
          label={buttonText}
        />
      </>
    </Modal>
  );
};

export default LoginModal;
